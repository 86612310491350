.wp-block-button[class*='has-icon__'] {
  .wp-block-button__link {
    display: flex;
    gap: 0.5em;
    align-items: center;

    span {
      line-height: 0;
    }

    svg {
      color: currentColor;
      fill: currentColor;
    }
  }

  &.has-icon__arrow-long-right,
  &.has-icon__arrow-long-left {
    .wp-element-button .wp-block-button__link-icon {
      @apply ease-out-expo transition-transform duration-150;
    }
  }

  &.has-icon__arrow-long-right {
    &:hover,
    &:focus-within {
      .wp-block-button__link-icon {
        transform: translateX(0.25em);
      }
    }
  }

  &.has-icon__arrow-long-left {
    &:hover,
    &:focus-within {
      .wp-block-button__link-icon {
        transform: translateX(-0.25em);
      }
    }
  }
}
